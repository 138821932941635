import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import Checkbox from 'expo-checkbox'
import { responsiveWidth } from 'react-native-responsive-dimensions'

const FormCheckBox = ({ children, ...props }) => (
  <View style={styles.container}>
    <Checkbox type={'checkbox'} style={styles.checkbox} {...props} />
    <Text style={styles.label}>{children}</Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  checkbox: {
    alignSelf: 'center',
  },
  label: {
    margin: responsiveWidth(3),
  },
})

export default FormCheckBox
