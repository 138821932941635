import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { viomsHttpRequest } from '../../app/viomsClient'

export const fetchEmailLists = createAsyncThunk('emailLists/fetchEmailLists', async (arg, { getState }) => {
  const token = getState().authentication.token
  const response = await viomsHttpRequest({ url: '/v2/email_lists', token })
  const json = await response.json()
  if (!response.ok) { throw json.error }
  return json
})

export const slice = createSlice({
  name: 'emailLists',
  initialState: {
    status: null,
    error: null,
    subscribedEmailLists: [],
    unsubscribedEmailLists: [],
    isRefreshing: false,
  },
  reducers: {
    resetStatus: state => {
      state.status = 'idle'
      state.error = null
    },
    startedRefreshing: state => {
      state.isRefreshing = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEmailLists.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(fetchEmailLists.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.subscribedEmailLists = action.payload.subscribed
        state.unsubscribedEmailLists = action.payload.unsubscribed
        state.error = null
        state.isRefreshing = false
      })
      .addCase(fetchEmailLists.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
        state.isRefreshing = false
      })
  },
})

export default slice.reducer

export const { resetStatus, startedRefreshing } = slice.actions
