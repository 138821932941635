import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, Text, Platform } from 'react-native'
import { WebView } from 'react-native-webview'

import { selectToken } from '../authentication/authenticationSlice'
import { fetchMailing, resetStatus } from './mailingSlice'
import StyledActivityIndicator from '../../app/components/StyledActivityIndicator'

const MailingScreen = ({ route, navigation }) => {
  const dispatch = useDispatch()
  const mailingStatus = useSelector(state => state.mailing.status)
  const error = useSelector(state => state.mailing.error)
  const mailing = useSelector(state => state.mailing.mailing)
  const { emailListId, mailingId } = route.params
  const token = useSelector(selectToken)

  React.useEffect(() => {
    if (mailingStatus === 'idle')
      dispatch(fetchMailing({ emailListId, mailingId, token }))

    return navigation.addListener('focus', () => dispatch(resetStatus()))
  }, [mailingStatus, dispatch])

  if (mailingStatus === 'loading')
    return <StyledActivityIndicator />
  else if (mailingStatus === 'succeeded') {
    if (Platform.OS === 'web')
      return <iframe src={`https://www.vioms.ru/mailings/${mailing.id}/full`} width={'100%'} height={'100%'}></iframe>
    else
      return <WebView source={{ html: mailing.rendered_html }} />
  }
  else if (mailingStatus === 'failed')
    return <View><Text>{error}</Text></View>
  else
    return <View/>
}

export default MailingScreen
