import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlatList, Text, View, RefreshControl } from 'react-native'

import { fetchMailings, selectAllMailings, resetStatus, startedRefreshing } from './mailingsSlice'
import StyledActivityIndicator from '../../app/components/StyledActivityIndicator'
import listStyles from '../../app/components/ListStyles'

const MailingsScreen = ({ route, navigation }) => {
  const dispatch = useDispatch()

  const mailingsStatus = useSelector(state => state.mailings.status)
  const error = useSelector(state => state.mailings.error)
  const { emailListId } = route.params
  const allMailings = useSelector(selectAllMailings)
  const isRefreshing = useSelector(state => state.mailings.isRefreshing)

  React.useEffect(() => {
    if (mailingsStatus === 'idle')
      dispatch(fetchMailings({ emailListId }))

    return navigation.addListener('focus', () => dispatch(resetStatus()))
  }, [mailingsStatus, dispatch])

  const onRefresh = () => {
    dispatch(startedRefreshing())
    dispatch(fetchMailings({ emailListId }))
  }

  const fetchNextPage = () => {
    dispatch(fetchMailings({ emailListId }))
  }

  let content

  if (mailingsStatus === 'loading' && !isRefreshing)
    content = <StyledActivityIndicator />
  else if (mailingsStatus === 'succeeded')
    content = <FlatList
      data={allMailings}
      keyExtractor={({ id }) => id.toString()}
      renderItem={({ item }) => {
        const handlePress = () => {
          navigation.navigate('MailingsTab',
            { screen: 'Mailing', params: { emailListId, mailingId: item.id, subject: item.subject } })
        }
        return <Text style={listStyles.item} onPress={handlePress}>{ item.subject }</Text>
      }}
      onEndReached={fetchNextPage}
      onEndReachedThreshold={0.6}
      ItemSeparatorComponent={() => ( <View style={listStyles.itemSeparator} /> )}
      refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
    />
  else if (mailingsStatus === 'failed')
    content = <Text>{error}</Text>

  return <View style={listStyles.container}>{ content }</View>
}

export default MailingsScreen
