import { configureStore } from '@reduxjs/toolkit'

import authenticationReducer from '../features/authentication/authenticationSlice'
import signUpReducer from '../features/authentication/signUpSlice'
import profileReducer from '../features/authentication/profileSlice'
import editProfileReducer from '../features/authentication/editProfileSlice'
import timeZonesReducer from '../features/authentication/timeZonesSlice'
import emailListsReducer from '../features/emailLists/emailListsSlice'
import mailingsReducer from '../features/mailings/mailingsSlice'
import mailingReducer from '../features/mailing/mailingSlice'

export default configureStore({
  reducer: {
    authentication: authenticationReducer,
    signUp: signUpReducer,
    profile: profileReducer,
    editProfile: editProfileReducer,
    timeZones: timeZonesReducer,
    emailLists: emailListsReducer,
    mailings: mailingsReducer,
    mailing: mailingReducer,
  },
})
