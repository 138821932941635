import React from 'react'
import { TextField } from 'rn-material-ui-textfield'

export default ({ handleChange, handleBlur, name }) => (
  <TextField
    label='Имя'
    autoCompleteType='name'
    autoCapitalize='none'
    autoCorrect={false}
    onChangeText={handleChange('name')}
    onBlur={handleBlur('name')}
    value={name}
  />
)
