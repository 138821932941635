import React from 'react'
import { TextField } from 'rn-material-ui-textfield'

export default ({ handleChange, handleBlur, values }) => (
  <TextField
    label='Пароль'
    autoCompleteType='password'
    autoCorrect={false}
    secureTextEntry={true}
    onChangeText={handleChange('password')}
    onBlur={handleBlur('password')}
    value={values.password}
  />
)
