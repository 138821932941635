import React from 'react'
import { View, Text } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import StyledActivityIndicator from '../../../app/components/StyledActivityIndicator'
import FormCheckBox from '../../../app/components/FormCheckBox'
import { fetchEmailLists } from '../../emailLists/emailListsSlice'
import { fetchSubscriber } from '../profileSlice'
import errorAlert from '../../../app/errorAlert'
import { selectToken } from '../authenticationSlice'

export default ({ children, setFieldValue, values }) => {
  const token = useSelector(selectToken)
  let emailListsStatus
  let emailListsError
  let emailLists
  if (token) {
    emailListsStatus = useSelector(state => state.profile.status)
    emailListsError = useSelector(state => state.profile.error)
    emailLists = useSelector(state => state.profile.subscriber.emailLists)
  } else {
    emailListsStatus = useSelector(state => state.emailLists.status)
    emailListsError = useSelector(state => state.emailLists.error)
    emailLists = useSelector(state => state.emailLists.unsubscribedEmailLists)
  }
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (emailListsStatus === 'idle')
      if (token)
        dispatch(fetchSubscriber())
      else
        dispatch(fetchEmailLists())
    else if (emailListsStatus === 'failed')
      errorAlert(emailListsError)
  }, [token, emailListsStatus, dispatch])

  const renderEmailLists = () => {
    return emailLists.map(emailList => {
      return (
        <FormCheckBox
          key={emailList.id}
          value={values.selected_email_list_ids[emailList.id]}
          onValueChange={ nextValue => {
            handleEmailListCheckboxChange(setFieldValue, emailList.id, values, nextValue)
          }}
        >
          { emailList.name }
        </FormCheckBox>
      )
    })
  }

  const handleEmailListCheckboxChange = (setFieldValue, emailListId, formValues, value) => {
    let newValue = formValues['selected_email_list_ids']
    newValue[emailListId] = value
    setFieldValue('selected_email_list_ids', newValue)
  }

  let content
  if (emailListsStatus === 'loading')
    content = <StyledActivityIndicator />
  else if (emailListsStatus === 'succeeded')
    content = renderEmailLists()
  return (
    <View>
      <Text>{ children }</Text>
      { content }
    </View>
  )
}
