import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import EmailListsScreen from '../../features/emailLists/EmailListsScreen'
import MailingsScreen from '../../features/mailings/MailingsScreen'
import MailingScreen from '../../features/mailing/MailingScreen'

const Stack = createStackNavigator()

const MailingsStack = () => {
  return (
    <Stack.Navigator initialRouteName='EmailLists'>
      <Stack.Screen name='EmailLists' component={EmailListsScreen} options={{ title: 'Email рассылки' }}/>
      <Stack.Screen
        name='Mailings'
        component={MailingsScreen}
        options={({ route }) => ({ title: route.params.name })}
      />
      <Stack.Screen
        name='Mailing'
        component={MailingScreen}
        options={({ route }) => ({ title: route.params.subject })}
      />
    </Stack.Navigator>
  )
}

export default MailingsStack
