import { StyleSheet } from 'react-native'
import { responsiveFontSize, responsiveHeight } from 'react-native-responsive-dimensions'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    backgroundColor: '#21406a',
    color: '#fff',
    fontSize: responsiveFontSize(3),
    fontWeight: 'bold',
    margin: 0,
    paddingVertical: responsiveHeight(0.6),
    paddingHorizontal: responsiveHeight(1),
  },
  item: {
    width: '99%',
    color: '#212529',
    fontSize: responsiveFontSize(2.3),
    padding: responsiveFontSize(0.8),
  },
  itemSeparator: {
    height: responsiveHeight(0.1),
    backgroundColor: '#ccc',
  },
})

export default styles
