import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { viomsHttpRequest } from '../../app/viomsClient'
import { setToken } from './authenticationSlice'
import { resetStatus } from '../emailLists/emailListsSlice'

export const signUp = createAsyncThunk(
  'signUp/signUp',
  async ({ name, email, phone, set_password, password, password_confirmation, time_zone_id, email_list_ids },
         { dispatch }) => {
    let body
    if (set_password)
      body = { name, email, phone, set_password, password, password_confirmation, time_zone_id, email_list_ids }
    else
      body = { name, email, phone, time_zone_id, email_list_ids }
    body = JSON.stringify({ subscriber_user: body })
    const response = await viomsHttpRequest({ url: '/registration', method: 'POST', body })
    const json = await response.json()
    if (!response.ok) { throw json.error }
    dispatch(setToken(json.token))
    AsyncStorage.setItem('token', json.token)
    dispatch(resetStatus())
  }
)

export const slice = createSlice({
  name: 'signUp',
  initialState: {
    status: 'idle',
    error: null,
  },
  extraReducers: builder => {
    builder
      .addCase(signUp.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(signUp.fulfilled, state => {
        state.status = 'succeeded'
        state.error = null
      })
      .addCase(signUp.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export default slice.reducer
