import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import * as SplashScreen from 'expo-splash-screen'
import AsyncStorage from '@react-native-async-storage/async-storage'

import store from './app/store'
import Navigator from './app/navigation/Navigator'
import { restoreToken } from './features/authentication/authenticationSlice'

SplashScreen.preventAutoHideAsync();

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false)

  useEffect(() => {
    async function prepare() {
      AsyncStorage.getItem('token').then(data => {
        store.dispatch(restoreToken({ token: data }))
        setAppIsReady(true)
        SplashScreen.hideAsync()
      })
    }

    prepare();
  }, [])

  if (!appIsReady) {
    return null;
  }

  return (
    <Provider store={store}>
      <Navigator />
    </Provider>
  )
}
