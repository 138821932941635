import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { viomsHttpRequest } from '../../app/viomsClient'
import { resetStatus as resetEmailListsStatus } from '../emailLists/emailListsSlice'
import { resetStatus as resetMailingStatus } from '../mailing/mailingSlice'
import { resetStatus as resetMailingsStatus } from '../mailings/mailingsSlice'

export const fetchSubscriber = createAsyncThunk('profile/fetchSubscriber', async (arg, { getState }) => {
  const token = getState().authentication.token
  const response = await viomsHttpRequest({ url: '/registration', token })
  const json = await response.json()
  if (!response.ok) { throw json.error }
  return json
})

export const updateSubscriber = createAsyncThunk(
  'profile/updateSubscriber',
  async ({ name, email, phone, time_zone_id, email_list_ids }, { dispatch, getState }) => {
    const token = getState().authentication.token
    const body = JSON.stringify({ subscriber_user: { name, email, phone, time_zone_id, email_list_ids } })
    const response = await viomsHttpRequest({ url: '/registration', token, method: 'PATCH', body })
    if (!response.ok) {
      const json = await response.json()
      throw json.error
    }
    dispatch(fetchSubscriber())
    dispatch(resetEmailListsStatus())
    dispatch(resetMailingsStatus())
    dispatch(resetMailingStatus())
  }
)

export const slice = createSlice({
  name: 'profile',
  initialState: {
    subscriber: { name: '', email: '', phone: '', emailLists: null, timeZoneId: null },
    status: 'idle',
    error: null,
    updateStatus: 'idle',
    updateError: null,
  },
  reducers: {
    resetStatus: state => {
      state.status = 'idle'
    },
    resetUpdateStatus: state => {
      state.updateStatus = 'idle'
    },
    updateSubscriberData: (state, action) => {
      state.subscriber = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSubscriber.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(fetchSubscriber.fulfilled, (state, action) => {
        state.subscriber = action.payload.subscriber
        state.subscriber.emailLists = action.payload.email_lists
        state.subscriber.timeZoneId = action.payload.time_zone_id
        state.status = 'succeeded'
        state.error = null
      })
      .addCase(fetchSubscriber.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(updateSubscriber.pending, state => {
        state.updateStatus = 'loading'
        state.updateError = null
      })
      .addCase(updateSubscriber.fulfilled, state => {
        state.updateStatus = 'succeeded'
        state.updateError = null
      })
      .addCase(updateSubscriber.rejected, (state, action) => {
        state.updateStatus = 'failed'
        state.updateError = action.error.message
      })
  },
})

export default slice.reducer

export const { resetUpdateStatus, updateSubscriberData } = slice.actions
