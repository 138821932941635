import React from 'react'
import { View, Text, ScrollView, Button, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { responsiveWidth } from 'react-native-responsive-dimensions'

import StyledActivityIndicator from '../../app/components/StyledActivityIndicator'
import { requestRefreshSubscriber } from './editProfileSlice'
import { fetchSubscriber } from './profileSlice'
import { selectToken, signOut } from './authenticationSlice'
import { resetStatus as resetEmailStatus } from '../emailLists/emailListsSlice'
import errorAlert from '../../app/errorAlert'

const ProfileScreen = ({ navigation }) => {
  const token = useSelector(selectToken)
  const status = useSelector(state => state.profile.status)
  const error = useSelector(state => state.profile.error)
  const subscriber = useSelector(state => state.profile.subscriber)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (status === 'idle')
      dispatch(fetchSubscriber())
    else if (status === 'failed')
      errorAlert(error)
  }, [status, token, dispatch])

  const pressEdit = () => {
    dispatch(requestRefreshSubscriber())
    dispatch(resetEmailStatus())
    navigation.navigate('ProfileTab', { screen: 'EditProfile' })
  }

  let content
  if (status === 'loading')
    content = <StyledActivityIndicator />
  else if (status === 'succeeded')
    content = (
      <View style={styles.container}>
        <Text>Имя: {subscriber.name}</Text>
        <Text>Email: {subscriber.email}</Text>
        <Text>Телефон: {subscriber.phone}</Text>
        <Button onPress={pressEdit} title='Редактировать' />
        <Button onPress={() => dispatch(signOut())} title='Выйти' />
      </View>
    )

  return (
    <ScrollView>
      { content }
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: responsiveWidth(4),
  },
})

export default ProfileScreen
