import React from 'react'
import { TextField } from 'rn-material-ui-textfield'

export default ({ handleChange, handleBlur, values }) => (
  <TextField
    label='Подтверждение пароля'
    autoCompleteType='password'
    autoCorrect={false}
    secureTextEntry={true}
    onChangeText={handleChange('password_confirmation')}
    onBlur={handleBlur('password_confirmation')}
    value={values.password_confirmation}
  />
)
