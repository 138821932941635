import React from 'react'
import { Text } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { ListItem } from 'react-native-elements'

import StyledActivityIndicator from '../../../app/components/StyledActivityIndicator'
import errorAlert from '../../../app/errorAlert'
import { fetchTimeZones, selectAllTimeZones, selectTimeZoneById } from '../timeZonesSlice'

export default ({ setFieldValue, timeZoneId }) => {
  const timeZones = useSelector(selectAllTimeZones)
  const status = useSelector(state => state.timeZones.status)
  const error = useSelector(state => state.timeZones.error)
  const timeZoneName = useSelector(state => {
    return (timeZoneId && status === 'succeeded') ? selectTimeZoneById(state, timeZoneId).name : '-'
  })
  const dispatch = useDispatch()

  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    if (status === 'idle')
      dispatch(fetchTimeZones())
    else if (status === 'failed')
      errorAlert(error)
  }, [status, dispatch])

  const handlePress = (timeZoneId) => {
    setExpanded(!expanded)
    setFieldValue('time_zone_id', timeZoneId)
  }

  let content
  if (status === 'loading')
    content = <StyledActivityIndicator />
  else if (status === 'succeeded')
    content = (
      <>
        <Text>Часовой пояс</Text>
        <ListItem.Accordion
          content={
            <ListItem.Content>
              <Text>{timeZoneName}</Text>
            </ListItem.Content>
          }
          isExpanded={expanded}
          onPress={() => {setExpanded(!expanded)}}
        >
          {timeZones.map(timeZone => (
            <ListItem key={timeZone.id} onPress={() => handlePress(timeZone.id)} bottomDivider>
              <ListItem.Content>
                <Text>{timeZone.name}</Text>
              </ListItem.Content>
            </ListItem>
          ))}
        </ListItem.Accordion>
      </>
    )

  return <>{ content }</>
}
