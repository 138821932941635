let backendUrl

if (process.env.NODE_ENV === 'production')
  backendUrl = 'https://www.vioms.ru/api/mobile'
else
  backendUrl = 'http://lvh.me:3000/api/mobile'

export default {
  backendUrl,
}
