import React from 'react'
import { TextField } from 'rn-material-ui-textfield'

export default ({ handleChange, handleBlur, phone }) => (
  <TextField
    label='Телефон'
    autoCompleteType='tel'
    keyboardType='phone-pad'
    onChangeText={handleChange('phone')}
    onBlur={handleBlur('phone')}
    value={phone}
  />
)
