import React from 'react'
import { createMaterialBottomTabNavigator } from "@react-navigation/material-bottom-tabs"
import { MaterialCommunityIcons } from '@expo/vector-icons'

import AuthenticationStack from './AuthenticationStack'
import MailingsStack from './MailingsStack'

const Tab = createMaterialBottomTabNavigator()

const BottomTabs = () => {
  return (
    <Tab.Navigator
      initialRouteName='Home'
      barStyle={{ backgroundColor: '#343a40' }}
      labelStyle={{ fontSize: 12 }}
    >
      <Tab.Screen
        name='MailingsTab'
        component={MailingsStack}
        options={{
          tabBarLabel: 'Рассылки',
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons name='email-multiple-outline' color={color} size={26} />
          ),
        }}
      />
      <Tab.Screen
        name='ProfileTab'
        component={AuthenticationStack}
        options={{
          tabBarLabel: 'Профиль',
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons name='account' color={color} size={26} />
          ),
        }}
      />
    </Tab.Navigator>
  )
}

export default BottomTabs
