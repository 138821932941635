import React from 'react'
import { Button, ScrollView, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { responsiveWidth } from 'react-native-responsive-dimensions'

import { selectToken } from './authenticationSlice'
import { fetchSubscriber, updateSubscriber, resetUpdateStatus } from './profileSlice'
import { resetRefreshSubscriber } from './editProfileSlice'
import errorAlert from '../../app/errorAlert'
import StyledActivityIndicator from '../../app/components/StyledActivityIndicator'
import NameField from './fields/NameField'
import EmailField from './fields/EmailField'
import PhoneField from './fields/PhoneField'
import TimeZones from './fields/TimeZones'
import EmailSubscriptions from './fields/EmailSubscriptions'
import { extractEmailIds } from './signUpScreen'

const EditProfileScreen = ({ navigation }) => {
  const token = useSelector(selectToken)
  const status = useSelector(state => state.profile.status)
  const error = useSelector(state => state.profile.error)
  const subscriber = useSelector(state => state.profile.subscriber)
  const updateStatus = useSelector(state => state.profile.updateStatus)
  const updateError = useSelector(state => state.profile.updateError)
  const needRefreshSubscriber = useSelector(state => state.editProfile.needRefreshSubscriber)
  const initialValues = useSelector( state => {
    const selectedEmailListIds = state.profile.subscriber.emailLists.reduce((obj, item) => {
      return { ...obj, [item['id']]: item.checked}
    }, {})
    return {
      name: subscriber.name, email: subscriber.email, phone: subscriber.phone, time_zone_id: subscriber.timeZoneId,
      selected_email_list_ids: selectedEmailListIds,
    }
  })
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (needRefreshSubscriber) {
      dispatch(resetRefreshSubscriber())
      dispatch(fetchSubscriber())
    }
  }, [needRefreshSubscriber, dispatch])

  React.useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchSubscriber())
    }
    else if (status === 'failed')
      errorAlert(error)
  }, [status, token, dispatch])

  React.useEffect(() => {
    if (updateStatus === 'succeeded') {
      dispatch(resetUpdateStatus())
      navigation.navigate('ProfileTab', { screen: 'Profile' })
    }
    else if (updateStatus === 'failed')
      errorAlert(updateError)
  }, [updateStatus, dispatch])

  const onSubmit = ({ name, email, phone, time_zone_id, selected_email_list_ids }) => {
    const email_list_ids = extractEmailIds(selected_email_list_ids)
    dispatch(updateSubscriber({ name, email, phone, time_zone_id, email_list_ids }))
  }

  return (
    <ScrollView style={styles.container}>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
        {({ handleChange, handleBlur, handleSubmit, values, setFieldValue }) => (
          <>
            <NameField handleChange={handleChange} handleBlur={handleBlur} name={values.name} />
            <EmailField handleChange={handleChange} handleBlur={handleBlur} email={values.email} />
            <PhoneField handleChange={handleChange} handleBlur={handleBlur} phone={values.phone} />
            <TimeZones setFieldValue={setFieldValue} timeZoneId={values.time_zone_id} />
            <EmailSubscriptions setFieldValue={setFieldValue} values={values}>
              Выберите Email списки, на которые хотите подписаться:
            </EmailSubscriptions>
            { status === 'loading' || updateStatus === 'loading' ? <StyledActivityIndicator /> : <Button onPress={handleSubmit} title='Сохранить' /> }
          </>
        )}
      </Formik>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: responsiveWidth(2),
  },
})

export default EditProfileScreen