import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { viomsHttpRequest } from '../../app/viomsClient'

const mailingsAdapter = createEntityAdapter()

const initialState = mailingsAdapter.getInitialState({
  status: 'idle',
  page: 1,
  error: null,
  isRefreshing: false,
  last_page: false,
})

export const fetchMailings = createAsyncThunk(
  'mailings/fetchMailings',
  async ({ emailListId }, { getState }) => {
    if (getState().mailings.last_page)
      return
    const token = getState().authentication.token
    const page = getState().mailings.page
    const response = await viomsHttpRequest({ url: `/v2/email_lists/${emailListId}/mailings?page=${page}`, token })
    const json = await response.json()
    if (!response.ok) { throw json.error }
    return json
  }
)

export const slice = createSlice({
  name: 'mailings',
  initialState,
  reducers: {
    resetStatus: state => {
      mailingsAdapter.removeAll(state)
      state.page = 1
      state.last_page = false
      state.status = 'idle'
      state.error = null
    },
    startedRefreshing: state => {
      mailingsAdapter.removeAll(state)
      state.page = 1
      state.last_page = false
      state.isRefreshing = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMailings.pending, state => {
        if (state.page === 1)
          state.status = 'loading'
        else
          state.isRefreshing = true
        state.error = null
      })
      .addCase(fetchMailings.fulfilled, (state, action) => {
        state.isRefreshing = false
        state.status = 'succeeded'
        state.error = null
        if (state.last_page)
          return
        mailingsAdapter.upsertMany(state, action.payload.mailings)
        state.page += 1
        state.last_page = action.payload.last_page
      })
      .addCase(fetchMailings.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
        state.isRefreshing = false
      })
  },
})

export const { resetStatus, startedRefreshing } = slice.actions

export default slice.reducer

export const {
  selectAll: selectAllMailings,
} = mailingsAdapter.getSelectors(state => state.mailings)
