import React from 'react'
import { SectionList, Text, View, RefreshControl } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { selectToken } from '../authentication/authenticationSlice'
import { fetchEmailLists, startedRefreshing } from './emailListsSlice'
import StyledActivityIndicator from '../../app/components/StyledActivityIndicator'
import listStyles from '../../app/components/ListStyles'

const EmailListsScreen = ({ navigation }) => {
  const dispatch = useDispatch()

  const emailListsStatus = useSelector(state => state.emailLists.status)
  const error = useSelector(state => state.emailLists.error)
  const token = useSelector(selectToken)
  const subscribedEmailLists = useSelector(state => state.emailLists.subscribedEmailLists)
  const unsubscribedEmailLists = useSelector(state => state.emailLists.unsubscribedEmailLists)
  const isRefreshing = useSelector(state => state.emailLists.isRefreshing)

  React.useEffect(() => {
    if (emailListsStatus === 'idle') {
      dispatch(fetchEmailLists())
    }
  }, [emailListsStatus, token, dispatch])

  const onRefresh = () => {
    dispatch(startedRefreshing())
    dispatch(fetchEmailLists())
  }

  let content

  if (emailListsStatus === 'loading' && !isRefreshing)
    content = <StyledActivityIndicator />
  else if (emailListsStatus === 'succeeded') {
    let myEmailLists
    if (token)
      myEmailLists = subscribedEmailLists
    else
      myEmailLists = [{ id: 0, name: 'Зарегистрируйтесь или войдите в личный кабинет, чтобы увидеть свои подписки' }]

    content = <SectionList
      sections={[
        { title: 'Мои подписки', data: myEmailLists },
        { title: 'Остальные списки', data: unsubscribedEmailLists },
      ]}
      keyExtractor={({ id }) => id.toString()}
      renderItem={({ item }) => {
        const handlePress = () => {
          if (item.id > 0)
            navigation.navigate('MailingsTab',
              { screen: 'Mailings', params: { emailListId: item.id, name: item.name } })
          else
            navigation.navigate('ProfileTab', { screen: 'SignUp' })
        }
        return <Text style={listStyles.item} onPress={handlePress}>{ item.name }</Text>
      }}
      renderSectionHeader={({ section: { title } }) => (
        <Text style={listStyles.header}>{title}</Text>
      )}
      ItemSeparatorComponent={() => ( <View style={listStyles.itemSeparator} /> )}
      refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
    />
  }
  else if (emailListsStatus === 'failed')
    content = <Text>{error}</Text>

  return (
    <View style={listStyles.container}>
      { content }
    </View>
  )
}

export default EmailListsScreen
