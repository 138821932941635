import React from 'react'
import { useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'

import SignInScreen from '../../features/authentication/signInScreen'
import SignUpScreen from '../../features/authentication/signUpScreen'
import ProfileScreen from '../../features/authentication/profileScreen'
import EditProfileScreen from '../../features/authentication/editProfileScreen'

const Stack = createStackNavigator()

const AuthenticationStack = () => {
  const signedIn = useSelector(state => !!state.authentication.token)
  if (signedIn) {
    return (
      <Stack.Navigator>
        <Stack.Screen name='Profile' component={ProfileScreen} options={{title: 'Профиль'}} />
        <Stack.Screen name='EditProfile' component={EditProfileScreen} options={{title: 'Редактирование профиля'}} />
      </Stack.Navigator>
    )
  } else {
    return (
      <Stack.Navigator>
        <Stack.Screen name='SignUp' component={SignUpScreen} options={{title: 'Зарегистрироваться'}} />
        <Stack.Screen name='SignIn' component={SignInScreen} options={{title: 'Войти'}} />
      </Stack.Navigator>
    )
  }
}

export default AuthenticationStack
