import React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { enableScreens } from 'react-native-screens'

import BottomTabs from './BottomTabs'

enableScreens()

const Navigator = () => (
  <NavigationContainer>
    <BottomTabs />
  </NavigationContainer>
)


export default Navigator
