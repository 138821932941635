import React from 'react'
import { Button, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { TextField } from 'rn-material-ui-textfield'

import { signIn } from './authenticationSlice'
import StyledActivityIndicator from '../../app/components/StyledActivityIndicator'
import errorAlert from '../../app/errorAlert'

const SignInScreen = () => {
  const initialValues = { email: '', phone: '', password: '' }
  const authenticationStatus = useSelector(state => state.authentication.status)
  const error = useSelector(state => state.authentication.error)
  const dispatch = useDispatch()

  const onSubmit = ({ email, password }) => {
    dispatch(signIn({ email, password }))
  }

  React.useEffect(() => {
    if (authenticationStatus === 'failed')
      errorAlert(error)
  }, [authenticationStatus, dispatch])

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleChange, handleBlur, handleSubmit, values }) => (
        <ScrollView>
          <TextField
            label='Email'
            autoCompleteType='email'
            autoCapitalize='none'
            autoCorrect={false}
            keyboardType='email-address'
            onChangeText={handleChange('email')}
            onBlur={handleBlur('email')}
            value={values.email}
          />
          {/*<TextField*/}
          {/*  label='Телефон'*/}
          {/*  autoCompleteType='tel'*/}
          {/*  keyboardType='phone-pad'*/}
          {/*  onChangeText={handleChange('phone')}*/}
          {/*  onBlur={handleBlur('phone')}*/}
          {/*  value={values.phone}*/}
          {/*/>*/}
          <TextField
            label='Пароль'
            autoCompleteType='password'
            autoCorrect={false}
            secureTextEntry={true}
            onChangeText={handleChange('password')}
            onBlur={handleBlur('password')}
            value={values.password}
          />
          { authenticationStatus === 'loading' ? <StyledActivityIndicator /> : <Button onPress={handleSubmit} title='Войти' /> }
        </ScrollView>
      )}
    </Formik>
  )
}

export default SignInScreen
