import React from 'react'
import { Button, ScrollView, Text, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { responsiveWidth, responsiveFontSize } from 'react-native-responsive-dimensions'

import { signUp } from './signUpSlice'
import StyledActivityIndicator from '../../app/components/StyledActivityIndicator'
import FormCheckBox from '../../app/components/FormCheckBox'
import NameField from './fields/NameField'
import EmailField from './fields/EmailField'
import PhoneField from './fields/PhoneField'
import PasswordField from './fields/PasswordField'
import EmailSubscriptions from './fields/EmailSubscriptions'
import PasswordConfirmationField from './fields/PasswordConfirmationField'
import TimeZones from './fields/TimeZones'
import errorAlert from '../../app/errorAlert'

const SignUpScreen = ({ navigation }) => {
  const initialValues = {
    email: '', phone: '', set_password: false, password: '', password_confirmation: '', time_zone_id: null,
    selected_email_list_ids: {},
  }
  const signUpStatus = useSelector(state => state.signUp.status)
  const signUpError = useSelector(state => state.signUp.error)
  const dispatch = useDispatch()
  let [isSetPassword, setIsSetPassword] = React.useState(false)

  React.useEffect(() => {
    if (signUpStatus === 'failed')
      errorAlert(signUpError)
  }, [signUpStatus, dispatch])

  const onSubmit = ({ name, email, phone, set_password, password, password_confirmation, time_zone_id,
                      selected_email_list_ids }) => {
    const email_list_ids = extractEmailIds(selected_email_list_ids)
    dispatch(
      signUp({ name, email, phone, set_password, password, password_confirmation, time_zone_id, email_list_ids })
    )
  }

  const handleSignInPress = () => {
    navigation.navigate('ProfileTab', { screen: 'SignIn' })
  }

  const handleSetPasswordCheckboxChange = (setFieldValue, nextValue) => {
    setFieldValue('set_password', nextValue)
    setIsSetPassword(nextValue)
  }

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.alreadyRegisteredMsg}>Уже зарегистрированы? <Text onPress={handleSignInPress} style={styles.loginLink}>Войти</Text></Text>
      <Formik initialValues={initialValues} onSubmit={onSubmit} style={styles.border}>
        {({ handleChange, handleBlur, handleSubmit, values, setFieldValue }) => (
          <>
            <NameField handleChange={handleChange} handleBlur={handleBlur} name={values.name} />
            <EmailField handleChange={handleChange} handleBlur={handleBlur} email={values.email} />
            <PhoneField handleChange={handleChange} handleBlur={handleBlur} phone={values.phone} />
            <TimeZones setFieldValue={setFieldValue} timeZoneId={values.time_zone_id} />
            <FormCheckBox
              key='set_password'
              value={values.set_password}
              onValueChange={nextValue => handleSetPasswordCheckboxChange(setFieldValue, nextValue)}
            >
              Установить пароль
            </FormCheckBox>
            { isSetPassword && (
              <>
                <PasswordField handleChange={handleChange} handleBlur={handleBlur} values={values} />
                <PasswordConfirmationField handleChange={handleChange} handleBlur={handleBlur} values={values} />
              </>
            ) }
            <EmailSubscriptions setFieldValue={setFieldValue} values={values}>
              Выберите Email списки, на которые хотите подписаться:
            </EmailSubscriptions>
            { signUpStatus === 'loading' ? <StyledActivityIndicator /> :
              <Button onPress={handleSubmit} title='Зарегистрироваться' /> }
          </>
        )}
      </Formik>
    </ScrollView>
  )
}

const extractEmailIds = (selected_email_list_ids) => {
  let email_list_ids = []
  for (const [email_list_id, value] of Object.entries(selected_email_list_ids)) {
    if (value === true)
      email_list_ids.push(email_list_id)
  }
  return email_list_ids
}

const styles = StyleSheet.create({
  container: {
    margin: responsiveWidth(2),
  },
  border: {
    borderWidth: 5,
  },
  alreadyRegisteredMsg: {
    fontSize: responsiveFontSize(2.5),
  },
  loginLink: {
    color: '#2196F3', // TODO: Different color for iOS, see https://reactnative.dev/docs/button#color
  },
})

export default SignUpScreen
export { extractEmailIds }
