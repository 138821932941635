import React from 'react'
import { TextField } from 'rn-material-ui-textfield'

export default ({ handleChange, handleBlur, email }) => (
  <TextField
    label='Email'
    autoCompleteType='email'
    autoCapitalize='none'
    autoCorrect={false}
    keyboardType='email-address'
    onChangeText={handleChange('email')}
    onBlur={handleBlur('email')}
    value={email}
  />
)
