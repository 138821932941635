import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'editProfile',
  initialState: {
    needRefreshSubscriber: false,
  },
  reducers: {
    requestRefreshSubscriber: state => {
      state.needRefreshSubscriber = true
    },
    resetRefreshSubscriber: state => {
      state.needRefreshSubscriber = false
    },
  },
})

export default slice.reducer

export const { requestRefreshSubscriber, resetRefreshSubscriber } = slice.actions
