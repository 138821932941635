import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { viomsHttpRequest } from '../../app/viomsClient'
import { resetStatus } from '../emailLists/emailListsSlice'
import { updateSubscriberData, slice as profileSlice } from './profileSlice'

export const signIn = createAsyncThunk(
  'authentication/signIn',
  async ({ email, password }, { dispatch }) => {
    const body = JSON.stringify({ email, password })
    const response = await viomsHttpRequest({ url: '/token', method: 'POST', body })
    const json = await response.json()
    if (!response.ok) { throw json.error }
    dispatch(setToken(json.token))
    AsyncStorage.setItem('token', json.token)
    dispatch(resetStatus())
  }
)

export const restoreToken = ({ token }) => {
  return async dispatch => {
    if (token) {
      const response = await viomsHttpRequest({ url: '/token/1/validate', token })
      if (response.ok)
        dispatch(setToken(token))
    }
    dispatch(resetStatus())
  }
}

export const signOut = createAsyncThunk(
  'authentication/signOut',
  async (arg, { getState, dispatch }) => {
    const token = getState().authentication.token
    await viomsHttpRequest({ url: '/token/1', method: 'DELETE', token })
    dispatch(setToken(null))
    AsyncStorage.removeItem('token')
    dispatch(resetStatus())
    dispatch(updateSubscriberData(profileSlice.getInitialState().subscriber))
  }
)

export const slice = createSlice({
  name: 'authentication',
  initialState: {
    token: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signIn.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(signIn.fulfilled, state => {
        state.status = 'succeeded'
        state.error = null
      })
      .addCase(signIn.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(signOut.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(signOut.fulfilled, state => {
        state.status = 'succeeded'
        state.error = null
      })
      .addCase(signOut.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const { setToken } = slice.actions

export default slice.reducer

export const selectToken = state => state.authentication.token
