import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { viomsHttpRequest } from '../../app/viomsClient'

export const fetchMailing = createAsyncThunk('mailings/fetchMailing', async ({ emailListId, mailingId, token }) => {
  const response = await viomsHttpRequest({ url: `/email_lists/${emailListId}/mailings/${mailingId}`, token })
  const json = await response.json()
  if (!response.ok) { throw json.error }
  return json
})

export const slice = createSlice({
  name: 'mailing',
  initialState: {
    status: 'idle',
    error: null,
    mailing: null,
  },
  reducers: {
    resetStatus: state => {
      state.status = 'idle'
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMailing.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(fetchMailing.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.mailing = action.payload
        state.error = null
      })
      .addCase(fetchMailing.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

export const { resetStatus } = slice.actions

export default slice.reducer
