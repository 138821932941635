import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'

import { viomsHttpRequest } from '../../app/viomsClient'

const timeZonesAdapter = createEntityAdapter()

export const fetchTimeZones = createAsyncThunk('profile/fetchTimeZones', async () => {
  const response = await viomsHttpRequest({ url: '/time_zones' })
  const json = await response.json()
  if (!response.ok) { throw json.error }
  return json
})

const initialState = timeZonesAdapter.getInitialState({
  status: 'idle',
  error: null,
})

export const slice = createSlice({
  name: 'timeZones',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(fetchTimeZones.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(fetchTimeZones.fulfilled, (state, action) => {
        timeZonesAdapter.setAll(state, action.payload)
        state.status = 'succeeded'
        state.error = null
      })
      .addCase(fetchTimeZones.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export default slice.reducer

export const {
  selectAll: selectAllTimeZones,
  selectById: selectTimeZoneById,
} = timeZonesAdapter.getSelectors(state => state.timeZones)
